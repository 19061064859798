<template>
  <div class="initUserIdComponents">
    <div class="projectImage">
      <el-image class="imageLogo" fit="scale-down" :src="image"></el-image>
    </div>
    <div class="loadingText">
      <i class="el-icon-loading"></i>&nbsp;{{ $t("entering") }}
    </div>
  </div>
</template>

<script>
import { _decodeServer } from "~/basePlugins/base64";
export default {
  props: {
    image: {
      type: String,
    },
  },
  mounted() {
    this.handleUserInfo();
  },
  methods: {
    async handleUserInfo() {
      let user_id = this.$route.query.initialParams;
      let redirect_url = this.$route.query.redirect_url;
      if (this.PJSource == "10" || this.PJSource == "11") {
        let meetingInfo = await this.$store.dispatch(
          "baseStore/getMeeingBasicInfo",
          {
            meeting_id: this.$store.state.meeting_id,
          }
        );
      }
      if (user_id) {
        let result = await this.$store.dispatch(
          "baseStore/baseSign_getUserInfo",
          { user_id }
        );
        if (this.PJSource == "10" || this.PJSource == "11") {
          let enrollInfo = await this.$store.dispatch(
            "baseStore/baseSign_getMeetingEnrollInfo",
            {
              meeting_id: this.$store.state.meeting_id,
              user_id: this.$store.state.baseStore.userInfo.id,
              company_id: this.$store.state.baseStore.userInfo.company_id,
            }
          );
        }
        setTimeout(() => {
          if (redirect_url) {
            try {
              this.$router.push(this._decode(redirect_url));
            } catch (e) {
              this.$router.push(_decodeServer(redirect_url));
            }
          } else {
            this.$router.push("/");
          }
        }, 500);
      } else {
        setTimeout(() => {
          if (redirect_url) {
            try {
              this.$router.push(this._decode(redirect_url));
            } catch (e) {
              this.$router.push(_decodeServer(redirect_url));
            }
          } else {
            this.$router.push("/");
          }
        }, 500);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.initUserIdComponents {
  width: 100%;
  height: 100vh;
  background: #f1f4f7;
  position: relative;
  .projectImage {
    width: 100%;
    text-align: center;
    padding-top: 100px;
    .imageLogo {
      width: 500px;
      height: 250px;
    }
  }
  .loadingText {
    font-size: 24px;
    color: #666;
    text-align: center;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .el-icon-loading {
    color: #333;
  }
}
</style>